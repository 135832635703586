import { QuestionMarkCircleIcon, UserIcon } from '@heroicons/react/solid';
import { AccountCircleTwoTone, Apps, ExitToApp, List as ListIcon, RecentActors, Style, ViewCarousel, WebStories } from '@mui/icons-material';
import { Avatar, Box, Button, ClickAwayListener, Fade, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { BetaChip, PremiumChip } from 'components/atoms/Chips';
import { VerifyEmailModal } from 'components/Modals';
import { DEFAULT_ORGANIZATION_LOGO, SMALL_LOGO_URL } from 'lib/constants';
import useReduxLogout from 'lib/hooks/useReduxLogout';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import { getCurrentUsersPerMonth } from 'lib/utils';
import _ from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerAvatar, getCustomerEmail, getCustomerId, getCustomerRole, getEmailVerifiedStatus } from 'redux/customer/selectors';
import { getCurrentProjectName } from 'redux/project/selectors';
import * as localStorageSvc from 'services/LocalStorageSvc';
import { AccountPopper, LayoutContainer, ModalItem, SidebarButton, SidebarContainer, SidebarLogo, SpaceName, StyledListItem, SubscriptionWarningContainer } from './Layout.style';
const navigationElements = currentSlug => {
  return [{
    id: 'workspaces',
    name: 'layout.sidebar.workspaces',
    iconName: <Apps />,
    path: `/${currentSlug}`,
    pathnames: [`/[organization_slug]`],
    subscriptionRequired: true,
    multipleWorkspacesRequired: true,
    organizationRequired: true,
    projectRequired: false,
    dataIntercomTarget: 'My Workspaces'
  }, {
    id: 'stories',
    name: 'layout.sidebar.stories',
    iconName: <WebStories />,
    path: `/${currentSlug}/list`,
    pathnames: [`/[organization_slug]/list`, `/[organization_slug]/edit/[story_id]`],
    subscriptionRequired: true,
    multipleWorkspacesRequired: false,
    organizationRequired: true,
    projectRequired: true,
    dataIntercomTarget: 'My Stories'
  }, {
    id: 'widgets',
    name: 'layout.sidebar.widgets',
    iconName: <ViewCarousel />,
    path: `/${currentSlug}/widgets`,
    pathnames: [`/[organization_slug]/widgets`, `/[organization_slug]/widget/[widget_id]`],
    subscriptionRequired: true,
    multipleWorkspacesRequired: false,
    organizationRequired: true,
    projectRequired: true,
    dataIntercomTarget: 'My Widgets'
  },
  // {
  // 	id: 'leaderboards',
  // 	name: 'layout.sidebar.leaderboards',
  // 	iconName: <CustomIcon name='Trophy' height={25} width={25} margin={1} />,
  // 	path: `/${currentSlug}/leaderboards`,
  // 	pathnames: [`/[organization_slug]/leaderboards`, `/[organization_slug]/leaderboard/[leaderboard_id]`],
  // 	subscriptionRequired: true,
  // 	multipleWorkspacesRequired: false,
  // 	organizationRequired: true,
  // 	projectRequired: true,
  // 	dataIntercomTarget: 'Leaderboards',
  // 	isPremium: true,
  // },
  {
    id: 'interactions',
    name: 'layout.sidebar.interactions',
    iconName: <RecentActors />,
    path: `/${currentSlug}/interactions`,
    pathnames: [`/[organization_slug]/interactions`],
    subscriptionRequired: true,
    multipleWorkspacesRequired: false,
    organizationRequired: true,
    projectRequired: true,
    dataIntercomTarget: 'Inbox'
  }, {
    id: 'brands',
    name: 'layout.sidebar.brands',
    iconName: <Style />,
    path: `/${currentSlug}/brands`,
    pathnames: [`/[organization_slug]/brands`, `/[organization_slug]/brand/[brand_id]`],
    subscriptionRequired: true,
    multipleWorkspacesRequired: false,
    organizationRequired: true,
    projectRequired: true,
    dataIntercomTarget: 'Brands'
  }];
};
export default function Layout({
  children
}) {
  const router = useRouter();
  const verifyEmailRef = useRef();
  const email_verified = useSelector(getEmailVerifiedStatus);
  const currentEmail = useSelector(getCustomerEmail);
  const currentId = useSelector(getCustomerId);
  const organization = useSelector(state => state.organization);
  const isOrganizationSelected = !!organization.slug;
  const {
    featureSet: {
      users_per_month
    },
    isActive,
    object,
    status
  } = useSubscriptionFeatures();
  const shouldDisplayWarning = object === 'subscription' && !isActive && isOrganizationSelected;
  const shouldDisplayTrailEdition = object === 'subscription' && isOrganizationSelected && status === 'in_trial' && router.pathname !== '/[organization_slug]/edit/[story_id]';
  const shouldDisplayUsersWarning = isOrganizationSelected && isActive && getCurrentUsersPerMonth(organization?.interactions_count) >= users_per_month;
  useEffect(() => {
    if (localStorageSvc.isLoggedIn() && !email_verified) {
      verifyEmailRef.current.open();
    }
  }, [email_verified]);
  function renderWarningBanner() {
    if (shouldDisplayWarning) {
      return <SubscriptionWarning redirectTo={`/${organization.slug}/settings`} message={<FormattedMessage id='layout.sidebar.warning' />} buttonMessage={<FormattedMessage id='layout.sidebar.subscribe' />} />;
    }
    if (shouldDisplayTrailEdition) {
      return <SubscriptionWarning redirectTo={`/${organization.slug}/settings?tab=subscription`} message={<FormattedMessage id='layout.sidebar.warning.in_trial' />} buttonMessage={<FormattedMessage id='layout.sidebar.upgrade' />} />;
    }
    if (shouldDisplayUsersWarning) {
      ;
      <SubscriptionWarning redirectTo={`/${organization.slug}/settings?tab=subscription`} message={<FormattedMessage id='layout.sidebar.warning.users_per_month' />} buttonMessage={<FormattedMessage id='layout.sidebar.upgrade' />} />;
    }
    return null;
  }
  return <LayoutContainer>
			<Sidebar />

			<main>
				{renderWarningBanner()}
				<section>{children}</section>
			</main>

			<VerifyEmailModal verifyEmailRef={verifyEmailRef} email={currentEmail} customer_id={currentId} hasCloseButton={false} disableBackdropClick={true} disableEscapeKeyDown={true} />
		</LayoutContainer>;
}
Layout.propTypes = {
  children: PropTypes.node
};
const Sidebar = memo(function Sidebar() {
  const router = useRouter();
  const {
    onLogoutSuccess
  } = useReduxLogout();
  const userAvatar = useSelector(getCustomerAvatar);
  const currentProjectName = useSelector(getCurrentProjectName);
  const organization = useSelector(state => state.organization);
  const currentCustomerRole = useSelector(getCustomerRole);
  const {
    featureSet: {
      multiple_workspaces,
      leaderboards
    },
    isActive,
    isLicense
  } = useSubscriptionFeatures();
  const subscriptionFeatures = useSubscriptionFeatures();
  const isOrganizationSelected = !!organization.slug;
  const isDefaultLogo = organization.logo === DEFAULT_ORGANIZATION_LOGO;

  // Popper Menu state
  const [menuDialogOpened, setMenuDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState('');
  const handleAvatarClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuDialogOpen(prevState => !prevState);
  };
  const handleClickAway = () => {
    if (menuDialogOpened) setMenuDialogOpen(false);
  };
  return <SidebarContainer variant='permanent' anchor='left'>
			<List>
				<ListItem>
					<SidebarLogo src={SMALL_LOGO_URL} />
					{isActive && multiple_workspaces && <SpaceName>{currentProjectName}</SpaceName>}
				</ListItem>

				{navigationElements(organization.slug).filter(element => element.subscriptionRequired === isActive).filter(element => !element.multipleWorkspacesRequired || element.multipleWorkspacesRequired === multiple_workspaces).filter(element => !element.organizationRequired || isOrganizationSelected).filter(element => !element.projectRequired || !!currentProjectName).map(element => {
        // Hide leaderboards from license users
        if (element.id === 'leaderboards' && isLicense) return null;

        // Display disabled leaderboard button if feature not active
        if (element.id === 'leaderboards' && !leaderboards) {
          return <Tooltip key={element.id} title={<FormattedMessage id='layout.sidebar.leaderboards.warning' />} placement='right'>
									<StyledListItem id={element.name} data-intercom-target={element.dataIntercomTarget} $disabled={true} disabled>
										{element.iconName}
										<FormattedMessage id={element.name} />
										{element.isPremium && <Box position='absolute' top={5} right={4}>
												<PremiumChip />
											</Box>}
									</StyledListItem>
								</Tooltip>;
        }
        return <Link href={element.path} key={element.id} passHref legacyBehavior>
								<StyledListItem id={element.name} key={element.id} component='a' $isSelected={_.includes(element.pathnames, router.pathname)} data-intercom-target={element.dataIntercomTarget}>
									{element.iconName}
									<FormattedMessage id={element.name} />
									{element.isBeta && <Box position='absolute' top={6} right={6}>
											<BetaChip />
										</Box>}
									{element.isPremium && <Box position='absolute' top={5} right={4}>
											<PremiumChip />
										</Box>}
								</StyledListItem>
							</Link>;
      })}
			</List>

			<Box flexGrow={1} />

			{/* External link doesn't need Next.js Link. Use a tag to open in new tab */}
			<StyledListItem id='layout.sidebar.help' component='a' href='https://help.snackeet.com/' target='_blank' rel='noreferrer'>
				<Tooltip title={<FormattedMessage id='common.help.description' />} placement='right'>
					<SidebarButton>
						<>
							<QuestionMarkCircleIcon />
							<FormattedMessage id='common.help' />
						</>
					</SidebarButton>
				</Tooltip>
			</StyledListItem>

			<StyledListItem button $isSelected={menuDialogOpened} onClick={handleAvatarClick} data-test='sidebar-profile'>
				{userAvatar.url ? <Avatar src={userAvatar.url}>{userAvatar.letters}</Avatar> : <SidebarButton>
						<UserIcon />
						<FormattedMessage id='common.profile' />
					</SidebarButton>}
			</StyledListItem>

			<AccountPopper transition open={menuDialogOpened} anchorEl={anchorEl} placement='right-end'>
				{({
        TransitionProps
      }) => <ClickAwayListener onClickAway={handleClickAway}>
						<Fade {...TransitionProps} timeout={250}>
							<List component='nav' aria-label='main mailbox folders'>
								<Link href={'/'} legacyBehavior>
									<ModalItem button>
										<ListItemIcon>
											<ListIcon />
										</ListItemIcon>
										<ListItemText primary={<Typography variant='h2'>
													<FormattedMessage id='layout.sidebar.organizations_list.title' />
												</Typography>} secondary={<FormattedMessage id='layout.sidebar.organizations_list.description' />} />
									</ModalItem>
								</Link>

								{organization.logo && <Link href={`/${organization.slug}/settings?tab=profile`} legacyBehavior>
										<ModalItem button>
											<ListItemIcon>
												<Avatar style={{
                    height: '25px',
                    width: '25px',
                    borderRadius: isDefaultLogo ? 0 : '50%'
                  }} src={organization.logo} />
											</ListItemIcon>
											<ListItemText primary={<Typography variant='h2'>{organization.name}</Typography>} secondary={<FormattedMessage id='layout.sidebar.organization_current.description' />} />
										</ModalItem>
									</Link>}

								<Link href={'/account'} legacyBehavior>
									<ModalItem button>
										<ListItemIcon>
											<AccountCircleTwoTone />
										</ListItemIcon>
										<ListItemText primary={<Typography variant='h2'>
													<FormattedMessage id='layout.sidebar.account.title' />
												</Typography>} secondary={<FormattedMessage id='layout.sidebar.account.description' />} />
									</ModalItem>
								</Link>

								<ModalItem button onClick={() => onLogoutSuccess({
              redirectTo: '/login'
            })} data-test='logout'>
									<ListItemIcon>
										<ExitToApp />
									</ListItemIcon>
									<ListItemText>
										<Typography variant='h2'>
											<FormattedMessage id='button.logout' />
										</Typography>
									</ListItemText>
								</ModalItem>
							</List>
						</Fade>
					</ClickAwayListener>}
			</AccountPopper>
		</SidebarContainer>;
});
function SubscriptionWarning({
  redirectTo,
  message,
  buttonMessage
}) {
  const router = useRouter();
  function redirectToSubscription() {
    router.push(redirectTo);
  }
  return <SubscriptionWarningContainer>
			<Typography variant='h3'>{message}</Typography>
			<Button variant='contained' color='primary' size='small' onClick={redirectToSubscription}>
				{buttonMessage}
			</Button>
		</SubscriptionWarningContainer>;
}
SubscriptionWarning.propTypes = {
  redirectTo: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};